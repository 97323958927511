<template>
    <div>
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row class="d-flex justify-content-between w-100">
            <!-- Per Page -->
            <b-col md="6" class="d-flex mt-1">
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    v-model="per_page"
                    class="per-page-selector d-inline-block mx-50 w-25"
                    :options="['5', '10', '20', '50', '100']"
                    @input="changePagination()"
                />
                </b-col>
            <b-col md="6" class="d-flex mt-1 ml-100">
              <div class="w-100 d-flex justify-content-end">
                <div v-if="searchTerm.type == 'dropdown'">
                    <v-select
                      style="width: 200px"
                      placeholder="Search..."
                      :options="getOptions(searchTerm.storename)"
                      :label="searchTerm.displayName"
                      class="d-inline-block mr-1"
                      v-model="search"
                      @input="handleSearch()"
                    />
                  </div>
                  <div v-else>
                    <b-form-input
                      class="d-inline-block mr-1"
                      placeholder="Search..."
                      v-model="search"
                      @input="handleSearch()"
                    />
                  </div>
                <div class="ml-1">
                  <v-select
                    style="width: 150px !important"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :searchable="false"
                    v-model="searchTerm"
                    placeholder="Select"
                    class="per-page-selector d-inline-block mx-50"
                    label="name"
                    tbody-tr-class="custom-table-row"
                    :options="searchTermOptions"
                    @input="handleSearchType()"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
  
        <b-table
          :busy="isLoading"
          ref="refUserListTable"
          class="position-relative"
          :items="trainers"
          responsive
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          thead-class="head"
          :fields="tableColumns"
          @sort-changed="onSortChanged"
        >
          <template #table-busy>
            <div class="text-center text-secondary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <!-- {{ field.label }} -->
          <template #cell(id)="data">
            <span class="text-primary">
              {{ data.index + 1 }}
            </span>
          </template>
          <template #cell(f_name)="data">
            <div
              @dblclick="openTrainerProfile(data.item)"
              class="cursor-pointer font-weight-bolder d-flex align-items-center"
              style="font-size: 15px; font-family: 'Public Sans', sans-serif"
            >
              <span class="mr-1">
                <b-avatar
                  size="32"
                  :src="data.item.profilephoto"
                  :text="data.item.f_name.substring(0, 1)"
                />
              </span>
              {{ data.item.f_name }}
            </div>
          </template>
          <template #cell(specialized)="data">
            <div class="d-flex w-100 align-items-center font-weight-bold">
              <div>
                <p
                  class="m-0"
                  style="font-size: 15px; font-family: 'Public Sans', sans-serif"
                >
                  {{ setSepecialization(data.item.all_specialization) }}
                </p>
              </div>
            </div>
          </template>
  
          <template #cell(phone)="data">
            <div
              class="d-flex align-items-center font-weight-bolder"
              style="font-size: 15px; font-family: 'Public Sans', sans-serif"
            >
              <span>{{ data.item.phone }}</span>
            </div>
          </template>
          <template #cell(Action)="data">
            <div class="d-flex justify-content-start align-items-center">
                <feather-icon
                icon="EyeIcon"
                size="22"
                class="m-1"
                @click="openTrainerProfile(data.item)"
                style="cursor: pointer"
                />
                <feather-icon
                  icon="Trash2Icon"
                  size="22"
                  class="m-1"
                  style="cursor: pointer"
                  @click="removeRow(data.item)"
                />
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ pagination.from }} to {{ pagination.to }} of
                  {{ pagination.total }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BAvatar,
    BPagination,
    BSpinner,
    BFormInput
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  
  import axios from "../../../components/axios";
  import _ from "lodash";
  import moment from "moment";
  
  export default {
    components: {
        BCard,
        BFormInput,
        BRow,
        BCol,
        BButton,
        BTable,
        BAvatar,
        BPagination,
        BSpinner,
      moment,
      vSelect,
    },
    data() {
      return {
        tableColumns: [
          {
            key: "id",
            label: "ID",
          },
          {
            key: "f_name",
            label: "Trainers",
            sortable: true,
          },
          // { key: 'total', sortable: true, formatter: val => `$${val}` },
          {
            key: "specialized",
            label: "Specialized In Which Area",
            type: "dropdown",
            displayName: "name",
          },
          // { key: 'balance', sortable: true },
          {
            key: "phone",
            label: "Contact",
            sortable: true,
          },
          { key: "Action", label: "Actions" },
        ],
        newSearch: "",
        isSearchLoading: false,
        columnFilters: {},
        isDataLoading: false,
        searchTerm: { name: "Trainer", value: "trainer" },
        search: "",
        trainerSearch: "",
        sortBy: "",
        orderBy: true ? "ASC" : "DESC",
        // isSortDirDesc: true,
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        isLoading: false,
        paginationStart: 0,
        paginationEnd: 0,
        paginationValue: 1,
        paginationTotal: 0,
        per_page: 10,
        params: {},
        isLoading: false,
        pagination:{},
        trainers:[],
        searchTermOptions: [
          { name: "Trainer", value: "trainer" },
          {
            name: "Specialized",
            value: "specialized",
            type: "dropdown",
            displayName: "name",
            storename: "specialized",
          },
        ],
      };
    },
    computed: {
      setSepecialization() {
        return (item) => {
          let arr = item.map((inner) => {
            return inner.specialization ? inner.specialization.name : "";
          });
          return arr.filter(item => item).join(', ')
        };
      },
    },
    mounted() {
      this.getTrainerData();
    },
    methods: {

    async getTrainerData(id) {
      this.isDataLoading = false;
      this.isLoading = true;
      // this.params = {}
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      } else if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      // this.params.per_page = this.per_page
      //  let params = `?page=${this.paginationValue}&per_page=${this.per_page}${
      //   this.search ? "&search=" + this.search : ""
      // }${
      //   this.search && this.searchTerm ? "&type=" + this.searchTerm.value : ""
      // }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/get-rejected-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.trainers = json?.data.data.data;
          this.pagination = json?.data.data;
          this.paginationTotal = this.pagination.total;
          this.paginationStart = this.pagination.from;
          this.paginationEnd = this.pagination.to;
          this.totalTairner = json?.data.data.data.total;

          const paramId = parseInt(id);
          const filteredTrainers = json?.data.data.data.filter(
            (trainer) => trainer.id !== paramId
          );
          this.AllTrainer = filteredTrainers;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 
      
      onSortChanged(e) {
        this.sortBy = e.sortBy;
        this.orderBy = e.sortDesc ? "DESC" : "ASC";
        // console.log(this.sortBy)
        // console.log(this.orderBy)
        this.getTrainerData();
      },
      handleSearchType() {
        this.search = "";
        this.getTrainerData();
      },
      changePagination() {
        this.getTrainerData();
      },
      handleTrainerSearch: _.debounce(function () {
        this.getTrainers();
      }, 1000),
      handleSearch: _.debounce(function () {
        this.getTrainerData();
      }, 1000),
  
      async removeRow(item) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then(async (result) => {
            if (result.value) {
              await axios({
                method: "Delete",
                url: `${this.baseApi}trainer/delete/${item.id}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
              })
                .then((res) => {
                  this.$swal({
                    title: "Deleted",
                    text: "Trainer deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.getTrainerData();
                  this.$store.dispatch("app/getAllTrainer");
                  // this.$forceUpdate();
                })
                .catch((error) => {
                  console.error("Error deleting user:", error);
                });
            }
          })
  
          .catch((error) => console.log(error, "error"))
          .finally(() => {});
      },
      openTrainerProfile(item) {
        const UserId = item.id;
        this.$router.push({ path: `/trainerprofile/view/${UserId}` });
      },
    },
  };
  </script>